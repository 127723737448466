export const projects = [
  {
    title: "Crypto Price Tracker",
    subtitle: "React Framework",
    description:
      "This was my independent project that i undertook in my spare time and the project is a React JS website that would allow users to search crypto currency prices by fetching data from a public API.",
    image: "./cryptoProject.gif",
    link: "https://currency-price-tracker.netlify.app/",
  },
  {
    title: "IP Address Tracker",
    subtitle: "React Framework, CSS, TypeScript, Vite.js, Mapbox",
    description:
      "This project was developed to showcase my front-end development skills for websites such as showing making a website fully responsive, and allowed me to apply my knowledge of TypeScript into a personal project.",
    image: "./responsive.jpg",
    link: "https://ip-address-tracker-one-iota.vercel.app/",
  },
  {
    title: "CipherGuard",
    subtitle: "React.js, Vite, CSS",
    description:
      "This is a personal project that was created to generate passwords. The Features allow the users to specify the length of their passwords and choose to include lowercase/uppercase letters, numbers etc.",
    image: "./project.jpeg",
    link: "https://cipherguard.netlify.app/",
  },
  {
    title: "SpendWise",
    subtitle: "Vue, CSS, Vite.js, Vitest, PlayWright",
    description:
      "This is ma personal project that i have been working on and it's an Expense Tracker website which allows the user to Add expenses to track income/expenses, Update transactions, Remove transactions, View transactions in graphs, Export all current expenses/income and graphs to a PDF file for download.",
    image: "./expense.jpg",
    link: "https://spendwise-vue-expense.vercel.app/",
  },
];

export const skills = [
  "JavaScript",
  "React JS",
  "TypeScript",
  "Vue",
  "Jest",
  "NUnit",
  "HTML",
  "CSS",
  "C#",
  ".NET",
  "SQL",
  "Microsoft Azure",
  "AWS",
  "Docker",
  "Kubernetes",
  "Playwright",
  "Vite.js"
];
